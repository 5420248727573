<template>
    <div class="dashboard">
        <div class="dashboard__header">
            <h1>Рабочий стол</h1>
            <div class="dashboard_list_w">
                <vueScroll :ops="ops" class="dashboard_list_scroll">
                    <draggable
                        v-model="dashboardList"
                        class="dashboard_list"
                        draggable=".drag_item"
                        handle=".drag_handle"
                        ghost-class="ghost"
                        @start="dragging = true"
                        @end="dragging = false">
                        <div 
                            v-for="item in dashboardList" 
                            :key="item.id" 
                            :class="active === item.id && 'active'"
                            class="dashboard_list__item select-none drag_item">
                            <div class="name" @click="selectDashboard(item)">
                                {{ item.name }}
                            </div>
                            <div 
                                class="actions" 
                                :style="dragging && 'opacity: 0;'"
                                :class="[dashboardList.length === 1 && 'one']">
                                <div class="actions__item" @click="editDashboard(item)">
                                    <i class="fi fi-rr-pencil"></i>
                                </div>
                                <template v-if="dashboardList.length > 1">
                                    <div class="line"></div>
                                    <div class="actions__item drag_handle">
                                        <i class="fi fi-rr-arrows-alt"></i>
                                    </div>
                                    <div class="line"></div>
                                    <div class="actions__item" @click="deleteDashboard(item)">
                                        <i class="fi fi-rr-trash"></i>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div v-if="dLoading" class="dashboard_list__item active px-10">
                            <a-spin />
                        </div>
                    </draggable>
                </vueScroll>
                <div v-if="dashboardList.length < 8" class="ml-2">
                    <a-button 
                        type="ui" 
                        ghost 
                        flaticon 
                        shape="circle"
                        icon="fi-rr-plus"
                        @click="addDashboard()" />
                </div>
            </div>
            <div>
                <a-button 
                    type="primary" 
                    size="large"
                    icon="fi-rr-plus"
                    flaticon
                    @click="selectWidget()">
                    Добавить виджет
                </a-button>
            </div>
        </div>
        <div class="dashboard__body">
            <a-spin class="ds_spin" :spinning="loading">
                <component :is="dashboardWidget" />
            </a-spin>
        </div>
        <a-modal 
            v-model="visible" 
            :footer="false"
            destroyOnClose
            :afterClose="afterClose"
            :title="edit ? 'Редактировать рабочий стол' : 'Добавить рабочий стол'">
            <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules">
                <a-form-model-item ref="name" label="Название рабочего стола" prop="name">
                    <a-input v-model="form.name" size="large" />
                </a-form-model-item>
                <!--<a-form-model-item v-if="!edit && dashboardTemplate.length" ref="desktop_template" label="Шаблон рабочего стола" prop="desktop_template">
                    <div class="grid grid-cols-2 gap-4 d_templates">
                        <div 
                            v-for="item in dashboardTemplate" 
                            :key="item.id" 
                            class="d_templates__item" 
                            :class="(form.desktop_template && form.desktop_template === item.id) && 'active'"
                            @click="selectTemplate(item)">
                            {{ item.name }}
                        </div>
                    </div>
                </a-form-model-item>-->
                <a-button type="primary" size="large" block :loading="formLoading" @click="formSubmit">
                    Сохранить
                </a-button>
            </a-form-model>
        </a-modal>
        <WidgetsDrawer />
        <SettingDrawer />
    </div>
</template>

<script>
import WidgetsDrawer from './WidgetsDrawer/index.vue'
import SettingDrawer from './SettingDrawer/index.vue'
import draggable from "vuedraggable"
import vueScroll from 'vuescroll'
const updateKey = 'update_dashboard'
export default {
    components: {
        WidgetsDrawer,
        draggable,
        SettingDrawer,
        vueScroll
    },
    computed: {
        dashboardList: {
            get() {
                return this.$store.state.dashboard.dashboardList
            },
            set(val) {
                this.$store.dispatch('dashboard/updateDashboardPosition', val)
            }
        },
        active: {
            get() {
                return this.$store.state.dashboard.active
            },
            set(val) {
                this.$store.commit('dashboard/SET_ACTIVE', val)
            }
        },
        dashboardWidget() {
            return () => import('./Grid.vue')
        }
    },
    data() {
        return {
            dragging: false,
            form: {
                name: '',
                desktop_template: null
            },
            rules: {
                name: [
                    { required: true, message: this.$t('field_required'), trigger: 'change' },
                    { max: 100, message: this.$t('required_sym', { sym: 100 }), trigger: 'change' }
                ]
            },
            edit: false,
            dLoading: false,
            loading: false,
            formLoading: false,
            visible: false,
            dashboardTemplate: [],
            dashboardTemplateLoader: false,
            ops: {
                scrollPanel: {
                    scrollingY: false
                },
                vuescroll: {
                    mode: 'native',
                    locking: false
                },
                bar: {
                    background: "#ccc",
                    onlyShowBarOnScroll: false
                }
            }
        }
    },
    created() {
        if(!this.dashboardList.length)
            this.getDashboardList()
        else
            this.updatedState()
    },
    methods: {
        async updatedState() {
            try {
                this.$message.loading({ content: 'Обновление', key: updateKey })
                await this.$store.dispatch('dashboard/updatedState')
                this.$message.success({ content: 'Обновлено', key: updateKey, duration: 0.5 })
            } catch(e) {
                console.log(e)
                this.$message.error({ content: 'Ошибка обновления', key: updateKey, duration: 2 })
            }
        },
        selectTemplate(item) {
            if(this.form.desktop_template === item.id) {
                this.form.desktop_template = null
            } else {
                this.form.desktop_template = item.id
            }
        },
        addDashboard() {
            if(!this.dashboardTemplate.length)
                this.getDashboardTemplate()
            this.visible = true
        },
        async getDashboardTemplate() {
            try {
                this.dashboardTemplateLoader = true
                const { data } = await this.$http.get('/widgets/desktop_templates/')
                if(data?.length) {
                    this.dashboardTemplate = data
                }
            } catch(e) {
                console.log(e)
            } finally {
                this.dashboardTemplateLoader = false
            }
        },
        selectDashboard(item) {
            if(this.active !== item.id) {
                this.active = item.id
                localStorage.setItem('active_dashboard', item.id)
                this.getWidgets()
            }
        },
        async getDashboardList() {
            try {
                this.loading = true
                this.dLoading = true 
                await this.$store.dispatch('dashboard/getDashboardList') 
                this.getWidgets()
            } catch(e) {
                console.log(e)
                this.loading = false
            } finally {
                this.dLoading = false
            }
        },
        async getWidgets() {
            try {
                if(!this.loading)
                    this.loading = true
                await this.$store.dispatch('dashboard/getActiveWidgets', {
                    id: this.active
                })
            } catch(error) {
                if(error?.detail && error.detail.includes("Страница не найдена.")) {
                    localStorage.removeItem('active_dashboard')
                }
                console.log(error)
            } finally {
                this.loading = false
            }
        },
        editDashboard(item) {
            this.visible = true
            this.edit = true
            this.form = {...item}
        },
        reInit() {
            if(this.dashboardList.length) {
                this.active = this.dashboardList[0].id
                this.getWidgets()
            }
        },
        deleteDashboard(item) {
            this.$confirm({
                title: 'Вы действительно хотите удалить рабочий стол?',
                okText: 'Удалить',
                okType: 'danger',
                cancelText: 'Отмена',
                maskClosable: true,
                mask: true,
                closable: true,
                onOk: async () => {
                    try { 
                        await this.$store.dispatch('dashboard/deleteDashboard', {
                            id: item.id
                        }) 
                        if(this.active === item.id)
                            this.reInit()
                    } catch(e) {
                        console.log(e)
                    }
                }
            })
        },
        afterClose() {
            this.form = {
                name: '',
                desktop_template: null
            }
            this.edit = false
        },
        formSubmit() {
            this.$refs.ruleForm.validate(async valid => {
                if (valid) {
                    try {
                        this.formLoading = true
                        if(this.edit) {
                            const data = await this.$store.dispatch('dashboard/updateDashboard', {
                                form: this.form
                            }) 
                            if(data) {
                                this.$message.success(`Рабочий стол "${data.name}" обновлен`)
                                this.visible = false
                            }
                        } else {
                            const data = await this.$store.dispatch('dashboard/addDashboard', {
                                form: this.form
                            }) 
                            if(data) {
                                this.$message.success(`Рабочий стол "${data.name}" создан`)
                                this.visible = false
                                this.getWidgets()
                            }
                        }
                    } catch(e) {
                        console.log(e)
                    } finally {
                        this.formLoading = false
                    }
                } else {
                    return false;
                }
            })
        },
        selectWidget() {
            this.$store.commit('dashboard/SET_CATALOG_VISIBLE', true)
        }
    }
}
</script>

<style lang="scss" scoped>
.d_templates{
    &__item{
        border: 1px solid var(--border2);
        border-radius: var(--borderRadius);
        text-align: center;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        cursor: pointer;
        &.active,
        &:hover{
            border-color: var(--blue);
        }
    }
}
.dashboard_list_w{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: 0px;
    transform: translateX(-50%);
    height: 100%;
    max-width: calc(100% - 550px);
    overflow: hidden;
    @media (min-width: 1500px) {
        max-width: calc(100% - 450px);
    }
    .dashboard_list_scroll{
        &::v-deep{
            .__view{
                display: flex;
                align-items: center;
            }
        }
    }
}
.dashboard_list{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    &__item{
        cursor: pointer;
        margin: 0 5px;
        position: relative;
        .name{
            padding: 20px 15px;
            font-weight: 600;
            font-size: 14px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: 60px;
            text-align: center;
        }
        &:hover{
            .name{
                color: var(--blue);
            }
        }
        &.active{
            .name{
                color: var(--blue);
                &::after{
                    content: "";
                    background: var(--blue);
                    height: 3px;
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    width: 100%;
                    border-radius: 1px;
                }
            }
        }
        .actions{
            position: absolute;
            display: flex;
            align-items: center;
            top: 2px;
            right: -5px;
            z-index: 5;
            opacity: 0;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            &__item{
                font-size: 10px;
                cursor: pointer;
                background: var(--text);
                height: 18px;
                padding: 0 5px;
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                &:last-child{
                    border-radius: 0 18px 18px 0;
                }
                &:first-child{
                    border-radius: 18px 0 0 18px;
                }
                i{
                    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                }
                &:hover{
                    i{
                        opacity: 0.6;
                    }
                }
            }
            .line{
                background: #ffffff;
                height: 18px;
                width: 2px;
            }
            &.one{
                .actions__item{
                    border-radius: 18px;
                }
            }
        }
        &:hover{
            .actions{
                opacity: 1;
            }
        }
    }
}
.dashboard{
    height: 100%;
    overflow: hidden;
    display: flex;
    width: 100%;
    flex-direction: column;
    &__header{
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--border2);
        background: #ffffff;
        position: relative;
        h1{
            font-weight: 300;
            font-size: 24px;
            margin: 0px;
        }
    }
    &__body{
        flex-grow: 1;
        overflow: auto;
        padding: 10px 5px;
        .ds_spin{
            height: 100%;
            &::v-deep{
                & > div{
                    .ant-spin{
                        max-height: 100%;
                    }
                }
                & > .ant-spin-container{
                    height: 100%;
                }
            }
        }
    }
}
</style>